import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import Marquee from "react-fast-marquee";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { getSubtotal, removeAllFromCart } from "../features/products/cartSlice";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "../utils/currencyFormatter";

// import "./styles.css";

// import required modules
// import { Navigation } from "swiper/modules";

const LandingPage = () => {
  const navigate = useNavigate();
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubtotal());
  }, [data, dispatch]);

  const [response, setResponse] = useState(0);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    shipping_cost: "",
    shipping_type: "Cash On Delivery",
  });

  let grand_total = response.discount
    ? cartTotalAmount + +formData.shipping_cost + -response.discount
    : cartTotalAmount + +formData.shipping_cost + 0;

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.shipping_type === "Cash On Delivery") {
      try {
        const res = await fetch(`${process.env.REACT_APP_ORDER_URL}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            item: data,
            shipping_cost: formData.shipping_cost,
            shipping_type: formData.shipping_type,
            grand_total,
          }),
        });

        if (!res.ok) throw new Error("Something Went Error");
        if (res.ok) {
          dispatch(removeAllFromCart());
          navigate("/successpage");
        }
      } catch (err) {
        console.log(err.message);
      }
    }

    if (formData.shipping_type === "SSL Commerz") {
      const queryParams = new URLSearchParams({
        name: formData.name,
        // item: JSON.stringify(orderItem),
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        grand_total,
        shipping_type: formData.shipping_type,
        shipping_cost: formData.shipping_cost,
      });

      window.location.href = `${process.env.REACT_APP_PAYMENT_URL}/?${queryParams}`;
    }
  };

  return (
    <div className="flex flex-col gap-5 mb-5">
      {data?.map((product) => (
        <div key={product.id} className="flex flex-col gap-5 mb-5">
          <div className="bg-green-700 h-auto lg:h-96 w-full flex flex-col items-center justify-center gap-4">
            <div className="container mx-auto border-2 border-[#DDFF00] p-2">
              <div className="container mx-auto border-2 border-[#DDFF00] p-10 flex items-center justify-center">
                <span className=" text-base md:text-lg lg:text-2xl 2xl:text-4xl text-[#DDFF00]">
                  উচ্চ পুষ্টি গুণ ও এনার্জি বৃদ্ধিতে আমের গুরুত্ব অপরিসীম
                </span>
              </div>
            </div>
            {/* section-2 */}
            <div className="container mx-auto text-center flex flex-col gap-5">
              <span className="text-xl lg:text-3xl text-[#63E5FF]">
                বর্তমান বাজারে ভাল মানের আম কিংবা ফরমালিনমুক্ত আম পাওয়া কষ্টকর
                ব্যাপার। আমরা আপনাদের শতভাগ বিশুদ্ধ আমের নিশ্চয়তা দিচ্ছি ।
              </span>
              <span className="text-xl lg:text-3xl text-[#63E5FF]">
                বর্তমান বাজারে ভাল মানের আম কিংবা ফরমালিনমুক্ত আম পাওয়া কষ্টকর
                ব্যাপার। আমরা আপনাদের শতভাগ বিশুদ্ধ আমের নিশ্চয়তা দিচ্ছি ।
              </span>
            </div>
          </div>
          <div className="container mx-auto flex flex-col gap-10 items-center justify-center">
            <div className="hidden lg:block">
              <iframe
                width="1080"
                height="540"
                src="https://www.youtube.com/embed/17ImGzfTNEc?si=W_IS984CGTEUbO2t"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="block lg:hidden">
              <iframe
                width="400"
                height="400"
                src="https://www.youtube.com/embed/17ImGzfTNEc?si=W_IS984CGTEUbO2t"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="shadow-red-100 shadow-2xl border-2 border-black rounded-lg overflow-hidden">
              <button className="bg-[#FF0000] px-5 py-3 text-3xl text-white hover:bg-[#008519] duration-300">
                অর্ডার করতে চাই
              </button>
            </div>
          </div>
          <div className="container mx-auto bg-[#2C6036] text-center px-3 py-4 rounded-md overflow-hidden">
            <span className="text-white text-2xl lg:text-4xl">
              এই আমটি ল্যাংড়ার চেয়েও অনেক সুমিষ্ট। এর টোটাল সলিউবল সুগার
              (টিএসএস) প্রায় ২১ শতাংশ। আর ল্যাংড়ার টিএসএস ১৭ থেকে ১৯ শতাংশ।
              ক্ষিরসাপাত অর্থা ৎ হিমসাগরের মিষ্টতা ১৯ থেকে ২০ শতাংশ।
            </span>
          </div>

          <div className="container mx-auto border-[10px] border-green-800 py-3 px-4 flex flex-col gap-10">
            <div className="bg-green-900 flex items-center justify-center w-full p-5 rounded-md overflow-hidden">
              <span className="text-white md:text-2xl lg:text-4xl">
                ভাল মানের আম খান, নিজেকে সুস্থ রাখুন ।
              </span>
            </div>
            {/* slider */}
            <div className="">
              <Swiper navigation={true} className="mySwiper">
                <SwiperSlide>
                  <img
                    src="https://images.pexels.com/photos/2935021/pexels-photo-2935021.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://images.pexels.com/photos/6102300/pexels-photo-6102300.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://images.pexels.com/photos/2895712/pexels-photo-2895712.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="flex items-center justify-center rounded-lg overflow-hidden">
              <button className="bg-[#FF0000] shadow-2xl shadow-red-500 px-5 py-3 text-3xl text-white hover:bg-[#008519] duration-300">
                অর্ডার করতে চাই
              </button>
            </div>
          </div>

          <div className="container mx-auto border-[10px] border-green-800 py-3 px-4 flex flex-col gap-10">
            <div className="bg-white shadow-2xl rounded-2xl overflow-hidden px-10 py-5 lg:px-40 lg:py-10">
              <div className="flex items-center gap-5 text-base md:text-2xl lg:text-4xl">
                <span>
                  <img
                    src="https://www.demo12.softitglobal.com/frontend/images/check.webp"
                    alt=""
                    className="w-10"
                  />
                </span>
                <span clas>প্রতি কেজিতে ধরে ৩-৪ টি আম</span>
              </div>
              <div className="flex items-center gap-5 text-base md:text-2xl lg:text-4xl">
                <span>
                  <img
                    src="https://www.demo12.softitglobal.com/frontend/images/check.webp"
                    alt=""
                    className="w-10"
                  />
                </span>
                <span clas>বড় আকৃতির আম এর ওজন ৩০০ থেকে ৩৫০ গ্রাম</span>
              </div>
              <div className="flex items-center gap-5 text-base md:text-2xl lg:text-4xl">
                <span>
                  <img
                    src="https://www.demo12.softitglobal.com/frontend/images/check.webp"
                    alt=""
                    className="w-10"
                  />
                </span>
                <span clas>আঁশ বিহীন আম, স্বাদে ও গন্ধে অতুলনীয়</span>
              </div>
              <div className="flex items-center gap-5 text-base md:text-2xl lg:text-4xl">
                <span>
                  <img
                    src="https://www.demo12.softitglobal.com/frontend/images/check.webp"
                    alt=""
                    className="w-10"
                  />
                </span>
                <span clas>বড় আকৃতির আম এর ওজন ৩০০ থেকে ৩৫০ গ্রাম</span>
              </div>
            </div>
            <div className="bg-white shadow-2xl rounded-2xl overflow-hidden px-40 py-10"></div>
          </div>

          <div className="container mx-auto border-[10px] border-green-800 py-3 px-4 grid lg:grid-cols-[45%_50%] gap-10 overflow-hidden">
            <form onSubmit={handleFormSubmit}>
              <div className="billing-address flex flex-col order-2 md:order-1">
                <h2 className="text-2xl mt-5 md:mt-0 mb-5">Billing Address</h2>
                <div className="form-control flex flex-col gap-2  mb-3">
                  <label className="mb-2">আপনার নাম</label>
                  <input
                    required
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    value={formData.name}
                    className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                    placeholder="Enter Your First Name"
                  />
                </div>
                <div className="form-control flex flex-col mb-3">
                  <label className="mb-2">মোবাইল নাম্বার</label>
                  <input
                    required
                    type="tel"
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                    value={formData.phone}
                    className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300"
                    placeholder="Enter Your Number"
                  />
                </div>
                <div className="form-control flex flex-col mb-3">
                  <label className="mb-2">আপনার ইমেল</label>
                  <input
                    className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    value={formData.email}
                    type="email"
                    placeholder="Enter Your Email..."
                  />
                </div>

                <div className="form-control flex flex-col  mb-3 ">
                  <label className="mb-2">আপনাকে ঠিকানা</label>
                  <textarea
                    required
                    type="text"
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                    value={formData.address}
                    className="px-3 py-2 w-full rounded-md outline-none border focus:border-[#96680e] duration-300 resize-none"
                    placeholder="Enter Your Address"
                  />
                </div>

                <div className="form-control flex flex-col gap-2  mb-3">
                  <select
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        shipping_cost: e.target.value,
                      })
                    }
                    className="px-3 py-2  rounded-md outline-none border focus:border-[#96680e] duration-300"
                  >
                    <option value="0">Select Area</option>
                    <option value="60">Inside Dhaka(60)</option>
                    <option value="100">Dhaka Subcity(100)</option>
                    <option value="120">Outside Dhaka(120)</option>
                  </select>
                </div>

                <div className="form-control flex items-end justify-between  ">
                  <div className="flex">
                    <button
                      type="submit"
                      className="bg-[#0ea5e9] px-5 py-3 rounded-md"
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <div className="order-details">
              <h2 className="text-2xl">Order Details</h2>

              <div className="h-auto overflow-y-scroll order-details-container">
                <div
                  key={product.id}
                  className="cart-items  h-20  flex  items-start gap-4 mt-3 overflow-hidden"
                >
                  <div className="cart-img aspect-square w-20 flex justify-center items-center overflow-hidden ">
                    <img
                      src={`${process.env.REACT_APP_PRODUCTS_IMAGE_URL}/${product?.photos}`}
                      className="w-full object-cover "
                      alt=""
                    />
                  </div>
                  <div className="cart-description w-full overflow-hidden ">
                    <div className="flex flex-col">
                      <p className="truncate">
                        {product?.name.substring(0, 60)}...
                      </p>
                      <div className="cart-price">
                        {product.after_discount > 0 ? (
                          <>
                            {currencyFormatter(
                              product?.after_discount * product?.cartQuantity
                            )}
                          </>
                        ) : (
                          <>
                            {currencyFormatter(
                              product?.unit_price * product?.cartQuantity
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="shipping_Type  flex  md:flex-col lg:flex-row gap-5 ">
                <div>
                  <label className="cursor-pointer">
                    <input
                      type="radio"
                      checked
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          shipping_type: e.target.value,
                        })
                      }
                      name="shipping_type"
                      value={"Cash On Delivery"}
                      className="peer sr-only"
                    />
                    <div className="w-full md:w-36 2xl:w-40  rounded-md bg-white p-1 text-gray-600 ring-2 ring-transparent transition-all hover:shadow peer-checked:text-sky-600 peer-checked:ring-blue-400 peer-checked:ring-offset-2">
                      <img
                        src="./cash_on_delivery.jpg"
                        alt=""
                        className="w-full h-12 "
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div className="payment-summary flex flex-col gap-3 mt-5 ">
                <div className="sub-total flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold">
                    Sub Total
                  </h4>
                  <span className="text-slate-700 ">
                    {currencyFormatter(cartTotalAmount)}
                  </span>
                </div>
                <div className="shipping flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold ">
                    Shipping
                  </h4>
                  <span className="text-slate-700 ">
                    {currencyFormatter(+formData.shipping_cost)}
                  </span>
                </div>
                <div className="shipping flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold ">
                    Discount
                  </h4>
                  <span className="text-slate-700 ">
                    {response.discount
                      ? currencyFormatter(+response.discount)
                      : currencyFormatter(0)}
                  </span>
                </div>
                <div className="sub-total flex justify-between">
                  <h4 className="text-lg text-slate-400 font-semibold">
                    Total
                  </h4>
                  <span className="text-slate-700 font-semibold ">
                    {currencyFormatter(grand_total)}
                  </span>
                </div>
                <div>
                  {/* <div className="flex   gap-3">
                      <input
                        type="text"
                        value={couponCode}
                        onChange={handleCouponCodeChange}
                        className="px-3 py-2 xl:w-72  w-60  rounded-md outline-none border"
                      />
                      <button
                        onClick={applyCoupon}
                        className="bg-[#0ea5e9] px-3  text-sm rounded-md"
                      >
                        Apply Coupon
                      </button>
                    </div> */}
                  {response.status === 200 && (
                    <p className="text-green-500 text-xs mt-2">
                      {response.massage}
                    </p>
                  )}
                  {response.status === 404 && (
                    <p className="text-rose-500 text-xs mt-2">
                      {response.massage}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LandingPage;
