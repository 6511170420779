import { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCall } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { BsCart3 } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";

import { IoIosClose, IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import axios from "axios";
import Search from "./Search";

const Navbar = ({ handleMenu }) => {
  const [searchItem, setSearchItem] = useState("");
  const [toggle, setToggle] = useState(false);
  const inputField = useRef(null);
  const { items: data } = useSelector((state) => state.category);
  const { cartItems: product } = useSelector((state) => state.cart);

  const { items: logo } = useSelector((state) => state.logo);

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#083344" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  const [searchData, setSearchData] = useState([]);
  const [word, setWord] = useState("");
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.get(`${process.env.REACT_APP_URL}/api-products`);
      return setSearchData(res.data);
    };
    fetchSearchData();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    //form reset
    setSearchItem("");
    inputField.current.blur();
  };

  const handleChange = (e) => {
    const searchWord = e.target.value;
    setWord(searchWord);
    const newFilterData = searchData.filter((value) =>
      value.name.toLowerCase().includes(searchWord.toLowerCase())
    );

    if (searchWord === "") {
      setFilterData([]);
    } else {
      setFilterData(newFilterData);
    }
  };

  const handleClose3 = () => {
    setWord();
    setFilterData([]);
  };
  // const handleMenu = () => {
  //   setToggle((prev) => !prev);
  // };
  // const handleClose = () => {
  //   setToggle((prev) => !prev);
  // };
  return (
    <>
      <div className="sticky px-4 md:px-0 top-0 left-0  right-0 z-[9999] bg-white">
        {/* middle nav */}
        <div className="bg-white/75 py-5  ">
          <div className="container mx-auto flex  items-center justify-between">
            {/* <button className="md:hidden block duration-700 text-[#331b08]">
              <FiMenu onClick={handleMenu} className="text-[28px]  " />
            </button> */}
            <Link
              to={"/"}
              className="brand inline-block text-2xl italic font-semibold rounded-md overflow-hidden"
            >
              <div className="w-[14rem]">
                <img
                  src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                  className="w-full h-full object-cover"
                  alt=""
                />
              </div>
              {/* <img src="../../logo.png" className="w-[14rem]" alt="" /> */}
            </Link>

            {/* search button */}
            <div className="hidden md:block">
              <form
                onSubmit={handleSearch}
                className="relative flex items-center justify-center"
              >
                {/* <input
                  ref={inputField}
                  type="text"
                  value={searchItem}
                  placeholder="Search Items......"
                  onChange={(e) => setSearchItem(e.target.value)}
                  className=" bg-transparent border p-3 px-8  w-[20rem] rounded-full focus:outline-none "
                /> */}
                {/* <button className="absolute  rounded-full font-extrabold text-black/70 py-2 px-4 top-[10px] right-2">
                  <FiSearch />
                </button> */}
                <Search />
              </form>
            </div>

            {/* <form
              onSubmit={handleSearch}
              className="relative flex flex-col items-center justify-center"
            >
              <input
                ref={inputField}
                type="text"
                placeholder="Search Items......"
                onChange={handleChange}
                className=" bg-transparent border p-3 w-full border-red-500   rounded-md focus:outline-none"
              />
              {filterData.length === 0 ? (
                <FiSearch className="absolute right-3 text-xl text-gray-500" />
              ) : (
                <IoIosClose
                  onClick={handleClose3}
                  className="absolute right-3  text-2xl text-gray-500"
                />
              )}
              {filterData.length !== 0 && (
                <div className="search-content flex flex-col w-full z-[9999] gap-3 bg-white h-auto  p-2 overflow-hidden overflow-y-scroll">
                  {filterData.slice(0, 5).map((product) => (
                    <Link
                      onClick={handleClose3}
                      key={product.id}
                      to={`/productdetails/${product.id}`}
                      className="product-card flex items-center    gap-2"
                    >
                      <div className="product-image w-20 h-20 overflow-hidden">
                        <img
                          src={`${process.env.REACT_APP_URL}/uploads/product/${product.photos}`}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="product-content text-black w-full text-sm overflow-hidden">
                        <h4 className="font-semibold">{product?.brand_name}</h4>
                        <p className="truncate text-lg ">{product?.name}</p>
                        <span className="text-gray-400">
                          {product.unit_price}
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </form> */}

            <div className="hidden md:block">
              <div className="flex gap-1 items-center ">
                <MdOutlineCall />
                <p>
                  Help line <Link>+8801313888090</Link>
                </p>
              </div>
            </div>

            <Link to="/addtocart" className=" block md:hidden relative group">
              <BsCart3 />
              <span className=" absolute bottom-2 text-sm left-2 text-slate-50 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
                {product.length}
              </span>
            </Link>
          </div>
        </div>
        {/* lower nav */}
        <div className={`bg-[#0ea5e9] hidden md:block`}>
          <div className="container mx-auto">
            <div className="flex justify-center">
              <ul className="flex text-[0.9rem] text-violet-50  lg:text-[1rem] gap-5 py-2 justify-between items-center">
                <li>
                  <NavLink
                    style={navActive}
                    end
                    to={"/"}
                    className="hover:text-gray-300 duration-300"
                  >
                    Home
                  </NavLink>
                </li>
                {data?.slice(0, 6).map((navItem) => (
                  <li>
                    <NavLink
                      className="hover:text-gray-300 duration-300"
                      style={navActive}
                      to={`/categorypage/${navItem.id}`}
                      key={navItem.id}
                    >
                      {navItem.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* <div
          className={`bg-slate-50   ${
            toggle
              ? " absolute  left-[0rem] top-[5rem] ease-in-out duration-[500ms]"
              : " absolute  left-[-100rem] top-[5rem] ease-in-out duration-[500ms] "
          } md:hidden  w-full min-h-screen    z-[9999]  right-0 bottom-0 rounded-b-sm `}
        >
          <ul className=" flex flex-col mt-24  justify-center items-center gap-4 p-7">
            <li
              onClick={handleClose}
              className=" w-full flex justify-center items-center"
            >
              <NavLink
                end
                to={"/"}
                className=" w-full flex justify-center text-[#212121] duration-300 hover:text-[#D89307]"
              >
                Home
              </NavLink>
            </li>
            {data?.slice(0, 6).map((navItem) => (
              <li onClick={handleClose}>
                <NavLink
                  style={navActive}
                  to={`/categorypage/${navItem.id}`}
                  key={navItem.id}
                  className="text-slate-700 hover:text-slate-500 duration-300"
                >
                  {navItem.name}
                </NavLink>
              </li>
            ))}

            <li className=" text-slate-400  text-[0.9rem] flex items-center gap-2 duration-1000">
              <Link className=" hover:underline underline-offset-2 duration-300">
                Login
              </Link>
              <span className="text-slate-400/40">or</span>
              <Link className=" hover:underline underline-offset-2 duration-300 ">
                Registration
              </Link>

              <li onClick={handleClose}>
                <Link to="/addtocart" className=" relative group">
                  <BsCart3 />
                  <span className=" absolute bottom-2 text-sm left-2 text-slate-50 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
                    {product.length}
                  </span>
                </Link>
              </li>
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};

export default Navbar;
