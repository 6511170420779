import { configureStore } from "@reduxjs/toolkit";

import productReducer, {
  productFetching,
} from "../features/products/productSlice";
import cartReducer from "../features/products/cartSlice";
import categoryReducer, {
  categoryFetching,
} from "../features/products/categorySlice";
import bestSellingReducer, {
  bestSellingFetching,
} from "../features/products/bestSellingSlice";
import footerDetailsReducer, {
  footerDetails,
} from "../features/products/footerAboutSlice";

import bannerReducer, {
  bannerFetching,
} from "../features/banner/sliderBannerSlice";

import homeBannerReducer, {
  homeBannerFetching,
} from "../features/banner/homeBannerSlice";

import subCategoryReducer, {
  subCategoryFetching,
} from "../features/category/subCategorySlice";
import logoReducer, { logoFatching } from "../features/logo/logoSlice";

import pagesReducer, { pageFatching } from "../features/pages/pagesSlice";

export const store = configureStore({
  reducer: {
    //reducer in here
    products: productReducer,
    cart: cartReducer,
    category: categoryReducer,
    bestSell: bestSellingReducer,
    logo: logoReducer,
    footerDetails: footerDetailsReducer,
    banners: bannerReducer,
    homeBanner: homeBannerReducer,
    subCategor: subCategoryReducer,
    page: pagesReducer,
  },
});

store.dispatch(productFetching());
store.dispatch(categoryFetching());

store.dispatch(bestSellingFetching());
store.dispatch(footerDetails());
store.dispatch(bannerFetching());
store.dispatch(homeBannerFetching());
store.dispatch(subCategoryFetching());
store.dispatch(logoFatching());
store.dispatch(pageFatching());
