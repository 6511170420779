import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";

import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <div className="min-h-screen wrapper my-20 flex justify-center">
      <div className="text-center w-full lg:w-[50rem] flex flex-col items-center">
        <div className="flex justify-center items-center gap-2  text-2xl lg:text-3xl">
          <span className="text-green-500">
            <AiFillCheckCircle />
          </span>
          <h2>Your order has been confirmed!</h2>
        </div>
        <p className="my-3">
          Thank you for shopping with us.If you would like to more shopping
          please visit the link below.
        </p>
        <Link
          to={"/"}
          className="bg-gray-900 text-white h-12 w-full lg:w-72 hover:opacity-80 duration-300 flex items-center justify-center gap-2 font-medium uppercase"
        >
          continue shopping
        </Link>
      </div>
    </div>
  );
};

export default SuccessPage;
